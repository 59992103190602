body {
  color: #383838;
  text-shadow: -1px 1px #38383811;
  background: #f2f2f2;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  font-size: 20px;
  line-height: 2;
  display: flex;
}

.card {
  background: #f7f7f7;
  border: 1px solid #0000000d;
  border-radius: 4px;
  flex-direction: column;
  padding: 4px 16px;
  display: flex;
  box-shadow: 0 4px 4px #0000001a;
}

a {
  color: #333;
  font-weight: 300;
  text-decoration: none;
}

footer {
  margin-top: 2rem;
}

.emoji {
  font-size: 40px;
}

.mr-1 {
  margin-right: 8px;
}

.ml-1 {
  margin-left: 8px;
}

.row {
  align-items: center;
  display: flex;
}

.spacer {
  flex-grow: 1;
}

.button {
  text-align: center;
  background-color: #dedede;
  border-radius: 4px;
  min-width: 64px;
  padding: 0 8px;
  transition: background-color .3s, transform .3s, color .3s;
  display: inline-block;
}

.button:hover {
  color: #f7f7f7;
  background-color: #787878;
  transform: translateY(-1px);
}

.link {
  text-align: center;
  border-bottom: 1px solid #0000;
  padding: 0 8px;
  font-size: 18px;
  font-weight: 100;
  transition: background-color .3s, transform .3s, color .3s;
  display: inline-block;
}

.link:hover {
  border-bottom: 1px solid #787878aa;
}

@media (width <= 720px) {
  .card {
    width: 100%;
    margin: 4px;
  }

  .row {
    flex-direction: column;
  }

  p, span, h1 {
    text-align: center;
  }

  .ml-1, .mr-1 {
    margin-left: 0;
    margin-right: 0;
  }
}

/*# sourceMappingURL=index.ae63bc2c.css.map */
