body {
  min-height: 100vh;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f2f2;
  color: #383838;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif;
  font-size: 20px;
  line-height: 2;
  text-shadow: -1px 1px #38383811;
}
.card {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 4px 16px;
  border: 1px solid rgba(0,0,0,.05);
  box-shadow: 0 4px 4px rgba(0,0,0,.1);
  background: #F7F7F7;
}
a {
  color: #333333;
  text-decoration: none;
  font-weight: 300;
}
footer {
  margin-top: 2rem;
}
.emoji {
  font-size: 40px;
}
.mr-1 {
  margin-right: 8px;
}
.ml-1 {
  margin-left: 8px;
}
.row {
  display: flex;
  align-items: center;
}
.spacer {
  flex-grow: 1;
}
.button {
  display: inline-block;
  text-align: center;
  padding: 0px 8px;
  min-width: 64px;
  background-color: #DEDEDE;
  transition: background-color .3s ease, transform .3s ease, color .3s ease;
  border-radius: 4px;
}
.button:hover {
  color: #F7F7F7;
  background-color: #787878;
  transform: translateY(-1px);
}
.link {
  display: inline-block;
  text-align: center;
  padding: 0px 8px;
  transition: background-color .3s ease, transform .3s ease, color .3s ease;
  border-bottom: 1px solid transparent;
  font-weight: 100;
  font-size: 18px;
}
.link:hover {
  border-bottom: 1px solid #787878AA;
}
@media (max-width: 720px) {
  .card {
    width: 100%;
    margin: 4px;
  }
  .row {
    flex-direction: column;
  }
  p, span, h1 {
    text-align: center;
  }
  .ml-1, .mr-1 {
    margin-left: 0;
    margin-right: 0;
  }

 }